.row {
    width: 100%;
}

.container {
    width: 100%;
    padding: 0 16px;
}

.padding-top {
    padding-top: 16px;
}

.container:before,
.container:before,
.row:before,
.row:after {
    content: "";
    display: table;
    clear: both;
}

.col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1,
.col-s-6 {
    padding: 2px 4px;
    float: left;
}

.col-9 {
    width: 75%;
}

.col-8 {
    width: 66.666666666%;
}

.col-7 {
    width: 58.3333333333%;
}

.col-6 {
    width: 50%;
}

.col-5 {
    width: 41.6666666667%;
}

.col-4 {
    width: 33.3333333%;
}

.col-3 {
    width: 25%;
}

.col-2 {
    width: 16.6666666667%;
}

.col-1 {
    width: 8.33333333333%;
}

.col-offset-10 {
    margin-left: 83.3333333333%;
}

.col-offset-11 {
    margin-left: 91.6666666667%;
}

@media (max-width: 751px) {
    .col-s-6 {
        width: 50%;
    }
}