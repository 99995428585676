@import "./../css/variables";

.header {
    display: block;
    width: 100%;
    position: fixed;
    background-color: #000000;
    color: #ffffff;
    z-index: 4;
    top: 0;

    .menu-trigger {
        display: none;
        width: auto;
        padding: 8px 16px;
        font-size: 18px;
        cursor: pointer;

        .icon {
            height: 18px;
            width: 18px;
        }

        span {
            color: #ffffff;
            padding: 8px 8px;
            font-size: 18px;
        }
    }
}

.right {
    float: right;
}

.left {
    float: left;
}

.top {
    top: 0;
}

.header-item {
    color: #ffffff;
    padding: 8px 16px;
    font-size: 18px;
}