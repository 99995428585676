.main {
  transition: margin-left .4s;
}

.animated-left {
  animation: animate-left .4s;
}

@keyframes animate-left {
  0% {
    left: -300px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

.animated-opacity {
  animation: animate-opacity .4s;
}

@keyframes animate-opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}