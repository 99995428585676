.btn-group li {
    display: inline;
    padding: 8px 10px;
    background-color: #6c757d;
}

.horizontal-list li {
    display: inline;
    margin-bottom: 10px;
    margin-top: 10px;
}

.horizontal-list li {
    padding-left: 8px;
    padding-right: 8px;
    border-left: 1px solid #000000;
}

.horizontal-list li:first-child {
    padding-left: 0;
    border-left: none;
}

.btn-group li {
    border-left: 1px solid #5a6268;
}

.btn-group li:hover {
    background: #5a6268;
    cursor: pointer;
    color: #FFFFFF;
}

.btn-group li:first-child {
    border-radius: 20% 0 0 20%;
    border: none;
}

.btn-group li:last-child {
    border-radius: 0 20% 20% 0;
}

.requests-list th:first-child {
    width: 5%;
}

@media (max-width: 751px) {
    .requests-list th:first-child {
        width: 10%;
    }
}
