.table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
}

.table tbody tr:nth-child(odd) {
    background-color: #fff;
}

.table th {
    word-wrap: break-word;
}

.table tbody tr:hover {
    background-color: #ccc;
}

.table th {
    text-align: left;
}

.table td {
    text-align: left;
    word-wrap: break-word;
}

.table th, .table td {
    padding: 8px 8px;
}

.table .auto-width {
    width: auto;
}

@media (max-width: 751px) {
    .table .v-big {
        display: none;
    }
}