h3 {
    font-weight: normal;
    margin: 10px 0;
}

.bg-gray {
    background-color: #9e9e9e;
    color: #ffffff;
}

.bg-dark-gray {
    background-color: #616161;
    color: #ffffff;
}

input[type='text'] {
    width: 100%;
}

.list-default li {
    list-style-type: none;
}

.btn {
    appearance: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 10px;
    overflow: hidden;
    border: none;
    color: #ffffff;

    &:hover {
        background-color: #ccc;
        color: #000000;
    }
}

.btn-fluid {
    width: 100%;
}

.default-color {
    background-color: $main-theme-color;
    color: #ffffff;
}

.clear-fix:before, .clear-fix:after {
    content: "";
    display: table;
    clear: both;
}

.icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-position: center center;
    background-size: cover;
}

.icon-logout {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M13 4.00894C13.0002 3.45665 12.5527 3.00876 12.0004 3.00854C11.4481 3.00833 11.0002 3.45587 11 4.00815L10.9968 12.0116C10.9966 12.5639 11.4442 13.0118 11.9965 13.012C12.5487 13.0122 12.9966 12.5647 12.9968 12.0124L13 4.00894Z' fill='currentColor' /%3E%3Cpath d='M4 12.9917C4 10.7826 4.89541 8.7826 6.34308 7.33488L7.7573 8.7491C6.67155 9.83488 6 11.3349 6 12.9917C6 16.3054 8.68629 18.9917 12 18.9917C15.3137 18.9917 18 16.3054 18 12.9917C18 11.3348 17.3284 9.83482 16.2426 8.74903L17.6568 7.33481C19.1046 8.78253 20 10.7825 20 12.9917C20 17.41 16.4183 20.9917 12 20.9917C7.58172 20.9917 4 17.41 4 12.9917Z' fill='currentColor' /%3E%3C/svg%3E");
}

.icon-menu {
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' enable-background='new 0 0 512 512' height='512' viewBox='0 0 512 512' width='512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m464.883 64.267h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z' fill='%23fff'/%3E%3Cpath d='m464.883 208.867h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z' fill='%23fff'/%3E%3Cpath d='m464.883 353.467h-417.766c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.012-21.137-47.149-47.117-47.149z' fill='%23fff'/%3E%3C/svg%3E");
}