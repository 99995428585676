.table-wrapper {
    max-height: 700px;
    overflow-y: scroll;
}

.table .check-all-th {
    width: 10%;
}

.table .content-middle {
    text-align: center;
}

.table .cadnum-th {
    width: 30%;
    text-align: center;
}

.table .address-th {
    width: 60%;
}

.table tbody {
    max-height: 500px;
}