@import "./../css/variables";

.left-bar {
  position: fixed;
  width: 300px;
  background-color: #ffffff;
  height: 100%;
  z-index: 3;

  .avatar {
    width: 46px;
    border-radius: 50%;
  }

  .bar-item {
    text-align: center;
    display: block;
    float: left;
    padding: 8px 12px;

    &:hover {
      background-color: #ccc;
    }
  }

  hr {
    border: 0;
    border-top: 1px solid #eee;
    margin: 20px 0;
  }

  .nav-block {
    a {
      display: block;
      color: #000;
      padding: 10px 16px;

      &:hover {
        background-color: #ccc;
      }

      &.active {
        background-color: #2196F3;
        color: #fff;
      }
    }
  }
}

.overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
}

@media (min-width: $large-min-width) {
  .left-bar {
    display: block!important;
  }

  .overlay {
    display: none!important;
  }
}

@media (max-width: $medium-max-width) {
  .left-bar {
    display: none;
  }

  .main {
    margin-left: 0!important;
  }

  .menu-trigger {
    display: block!important;
  }
}