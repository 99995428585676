.wrapping-block {
    margin-top: 2rem;
    border: 1px solid #4caf50;
    border-radius: 5px;
    padding: 20px 5px 10px 5px;
    position: relative;
}

.wrapping-title {
    line-height: 1.5rem;
    font-size: 1.5rem;
    padding: 0 0.3rem;
    position: absolute;
    left: 5%;
    top: -0.75rem;
    background-color: rgba(255, 255, 255, 1);
}