@import "./../css/variables";

.login-form {
    width: 360px;
    background: #FFFFFF;
    height: 580px;
    padding: 80px 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    h1 {
        text-align: center;
        margin-bottom: 60px;
    }

    input[type=text], input[type=password] {
        border: none;
        border-radius: 0;
        border-bottom: 2px solid #616161;
        background: none;
    }
}

@media (max-width: $small-max-width) {
    .login-form {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: none;
    }
}