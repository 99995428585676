@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(./../fonts/ralewayregular.woff) format('woff');
}

*{
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Raleway", sans-serif;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background: #ffffff;
}